<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap31Logging"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 31: Logging" image-name="logging.jpg" image-alt="Logging"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>What is a log?</p></li>
<li><p>How to use the standard logging package?</p></li>
<li><p>How to set up and use logrus?</p></li>
<li><p>What are log levels?</p></li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Log</p></li>
<li><p>Log level</p></li>
<li><p>Severity</p></li>
<li><p>Syslog</p></li>
<li><p>Deamon</p></li>
</ul>
<div id="introduction" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> Introduction <a href="#introduction"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>A log is a structured and parsable message produced by an application installed on a system. It’s the developer’s task to add logs to its application.</p>
<p>An application without bugs does not exist; even if you apply the best practices, you will have to face at a certain point of time a failure. A failure can be recoverable, meaning that your system can handle it and continue to work. It can also be unrecoverable. In the last case, your application will stop working.</p>
<div id="failure-is-common" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> Failure is common <a href="#failure-is-common"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Imagine the following situation: your phone starts to ring in the middle of the night. The CEO is at the other end of the line. He informs you that the site you have developed is down. Customers cannot place orders. You have to make it work now. You connect to the machine, restart the program, but it won’t restart. With stress reaching high levels, you will try to figure out why.</p>
<p>With logs, you can investigate and discover the origin of an error. Without logs, it can be far more difficult. But even with logs, your task will not be easy.</p>
<p>The difficulty will decrease if you know :</p>
<ol type="1">
<li><p>When your system has failed</p></li>
<li><p>Where the error has been raised</p></li>
<li><p>Which user/operation was involved when the system failed.</p></li>
</ol>
<p>The worst thing that can happen to an application is to produce errors, but those errors stay unnoticed, and bugs are still there. A solid logging habit allows you to monitor your application and maintain it in good health.</p>
<div id="example-logs" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> Example logs <a href="#example-logs"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Here is an example of logs :</p>
<pre v-highlightjs><code class="go" v-pre >2018/10/14 20:08:08 System is starting
2018/10/14 20:08:08 Retrieving user 12
2018/10/14 20:08:08 Compute the total of invoice 1262663663</code></pre>
<p>As you can see, we have a timestamp followed by a string, which is the message.</p>
<div id="standard-log-package" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> Standard log package <a href="#standard-log-package"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>In the standard library the <span v-highlightjs><code class="go" v-pre style="display: inline">log</code></span> package implements a very simple yet powerful logger.</p>
<p>The first thing to do is to define the Output of your logger. If you omit to define it, it will be the standard error. In the example, we are going to log into a file :</p>
<pre v-highlightjs><code class="go" v-pre >// logging/main.go 

f, err := os.OpenFile(&quot;app.log&quot;, os.O_APPEND|os.O_CREATE|os.O_WRONLY, 0644)
if err != nil {
    log.Fatalf(&quot;error opening file: %v&quot;, err)
}
defer f.Close()
log.SetOutput(f)</code></pre>
<p>We use the package <span v-highlightjs><code class="go" v-pre style="display: inline">os</code></span> to open our file (<span v-highlightjs><code class="go" v-pre style="display: inline">os.OpenFile</code></span>) the call to this function takes the following parameters :</p>
<ol type="1">
<li><p>The name of the file : <span v-highlightjs><code class="go" v-pre style="display: inline">"app.log"</code></span></p></li>
<li><p>The <strong>flags</strong> describe how the file has to be opened by the system. In our case, we want to append data to it (<span v-highlightjs><code class="go" v-pre style="display: inline">O_APPEND</code></span>), create it if it does not exist (<span v-highlightjs><code class="go" v-pre style="display: inline">O_CREATE</code></span>) and open it in write-only mode (<span v-highlightjs><code class="go" v-pre style="display: inline">O_WRONLY</code></span>)</p></li>
<li><p>The set of permissions.<span v-highlightjs><code class="go" v-pre style="display: inline">0644</code></span> means that the user can read and write, the group and others can write. If the file does not exist, the set of permissions will be applied.</p></li>
</ol>
<p>We are calling <span v-highlightjs><code class="go" v-pre style="display: inline">defer.Close()</code></span>, to close the file when the main function has returned.</p>
<p>Then we set the output of the standard logger to this opened file (<span v-highlightjs><code class="go" v-pre style="display: inline">log.SetOutput</code></span> takes an <span v-highlightjs><code class="go" v-pre style="display: inline">io.Writer</code></span>)</p>
<p>We are ready to log :</p>
<pre v-highlightjs><code class="go" v-pre >// logging/main.go 
log.Println(&quot;System is starting&quot;)
log.Println(&quot;Retrieving user 12&quot;)
log.Println(&quot;Compute the total of invoice 1262663663&quot;)</code></pre>
<p>To log, you can use the functions <span v-highlightjs><code class="go" v-pre style="display: inline">Print</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">Printf</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">Println</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">Fatal</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">Fatalf</code></span>...</p>
<div id="log.print-log.println-log.printf" class="anchor"></div>
<h2 data-number="6.1"><span class="header-section-number">6.1</span> log.Print, log.Println, log.Printf <a href="#log.print-log.println-log.printf"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">log.Print("System failure caused by lost DB connexion")</code></span> will output to the standard logger :</p>
<pre v-highlightjs><code class="go" v-pre >2018/10/14 20:08:08 System failure caused by lost DB connexion</code></pre>
<p>If you call <span v-highlightjs><code class="go" v-pre style="display: inline">log.Println("xyz")</code></span> the execution result of <span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Sprintln("xyz")</code></span> will be printed to the standard logger :</p>
<pre v-highlightjs><code class="go" v-pre >log.Println(&quot;System failure caused by lost DB connexion&quot;)</code></pre>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">log.Printf</code></span> is interesting. It allows you to add to your message formatted variables. You can use <strong>format specifiers</strong> to tell Go how it should output the variable.</p>
<pre v-highlightjs><code class="go" v-pre >dbIp:=&quot;192.168.210.12&quot;
dbPort := 3306
log.Printf(&quot;System failure caused by lost DB connexion to host %s at port %d&quot;, dbIp,dbPort)</code></pre>
<p>Will output the following :</p>
<pre v-highlightjs><code class="go" v-pre >2018/10/16 13:29:07 System failure caused by lost DB connexion to host 192.168.210.12 at port 3306</code></pre>
<div id="log.fatal-log.fatalln-log.fatalfsubseclog.fatal-log.fatalln-log.fatasubseclog.fatal-log.fatalln-log.fata-labelsubseclog.fatal-log.fatalln-log.fata" class="anchor"></div>
<h2 data-number="6.2"><span class="header-section-number">6.2</span> log.Fatal, log.Fatalln, log.Fatalf[[subsec:log.Fatal,-log.Fatalln,-log.Fata]]{#subsec:log.Fatal,-log.Fatalln,-log.Fata label=“subsec:log.Fatal,-log.Fatalln,-log.Fata”} <a href="#log.fatal-log.fatalln-log.fatalfsubseclog.fatal-log.fatalln-log.fatasubseclog.fatal-log.fatalln-log.fata-labelsubseclog.fatal-log.fatalln-log.fata"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>If you are calling one of those function, it will cause the program to shut down immediately. Internally <span v-highlightjs><code class="go" v-pre style="display: inline">log.Fatal(ln|f)</code></span> will call <span v-highlightjs><code class="go" v-pre style="display: inline">os.Exit(1)</code></span>. Everything will be stopped, and the deferred functions will not be called. It’s a sort of emergency button. The program does not terminate properly when you do a fatal.</p>
<dl>
<dt>log.Fatal</dt>
<dd><p>will output to the standard logger the return value of <span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Sprint</code></span> and call <span v-highlightjs><code class="go" v-pre style="display: inline">os.Exit(1)</code></span></p>
</dd>
<dt>log.Fatalf</dt>
<dd><p>the return value of <span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Sprintf</code></span> will be printed to the standard logger and <span v-highlightjs><code class="go" v-pre style="display: inline">os.Exit(1)</code></span>will be called</p>
</dd>
<dt>log.Fatalln</dt>
<dd><p>the return value of <span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Sprintln</code></span> will be printed to the standard logger and <span v-highlightjs><code class="go" v-pre style="display: inline">os.Exit(1)</code></span> will be called</p>
</dd>
</dl>
<div id="log.panic-log.panicln-log.panicf" class="anchor"></div>
<h2 data-number="6.3"><span class="header-section-number">6.3</span> log.Panic, log.Panicln, log.Panicf <a href="#log.panic-log.panicln-log.panicf"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>The same logic as above is used for calls to <strong>log.Panic[f][ln]</strong> there is no difference, <span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Sprint</code></span> , <span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Sprinf</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Sprintln</code></span> is called under the hood.</p>
<p>The only difference is that we have a call to <span v-highlightjs><code class="go" v-pre style="display: inline">panic</code></span> (instead of an <span v-highlightjs><code class="go" v-pre style="display: inline">os.Exit(1)</code></span>).</p>
<p>When the logger is calling <span v-highlightjs><code class="go" v-pre style="display: inline">panic</code></span> on your behalf :</p>
<ol type="1">
<li><p>Any deferred functions defined in the current function will be called</p></li>
<li><p>The current function will return to its caller</p></li>
<li><p>In the caller, all deferred functions defined will be called, and the caller will return to the function that called it...</p></li>
</ol>
<p>A call to <span v-highlightjs><code class="go" v-pre style="display: inline">panic</code></span> will call all deferred functions that are defined in the goroutine call stack.</p>
<div id="logrus" class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> Logrus <a href="#logrus"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The functions defined on the standard logger are limited, and you might want to add fancy features to your application’s logger. You can use a popular module called logrus (more than 17k stars on Github)<a href="#fn1" class="footnote-ref" id="fnref1" role="doc-noteref"><sup>1</sup></a>.</p>
<div id="setup" class="anchor"></div>
<h2 data-number="7.1"><span class="header-section-number">7.1</span> Setup <a href="#setup"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>First, you need to install it (after initializing your module with <span v-highlightjs><code class="go" v-pre style="display: inline">go mod init your/module/path</code></span>) :</p>
<pre v-highlightjs><code class="go" v-pre >go get github.com/sirupsen/logrus</code></pre>
<p>Then you can configure your logger :</p>
<pre v-highlightjs><code class="go" v-pre >file, err := os.OpenFile(&quot;app.log&quot;, os.O_CREATE|os.O_WRONLY|os.O_APPEND, 0644)
if err != nil {
    // handle error
}
log.SetOutput(file)</code></pre>
<p>Here we will log to a file. You can also log to the standard error (stderr). In this case, you can omit the last lines.</p>
<p>Then you can begin to log :</p>
<pre v-highlightjs><code class="go" v-pre >log.Print(&quot;my first log&quot;)</code></pre>
<p>Here is the output :</p>
<pre v-highlightjs><code class="go" v-pre >time=&quot;2021-02-19T12:28:57+01:00&quot; level=info msg=&quot;my first log&quot;</code></pre>
<div id="levels" class="anchor"></div>
<h2 data-number="7.2"><span class="header-section-number">7.2</span> Levels <a href="#levels"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>An interesting feature of logrus is to handle log levels. Each log has a level of criticality. With a level, you can apply filters to your log files. Here is an example of a log for each level available :</p>
<pre v-highlightjs><code class="go" v-pre >// logging/main.go 

log.Trace(&quot;going to mars&quot;)
log.Debug(&quot;connected, received buffer from worker&quot;)
log.Info(&quot;connection successful to db&quot;)
log.Warn(&quot;something went wrong with x&quot;)
log.Error(&quot;an error occurred in worker x&quot;)
log.Fatal(&quot;impossible to continue exec&quot;)
log.Panic(&quot;system emergency shutdown&quot;)</code></pre>
<ul>
<li><p>trace: very detailed information about the program run, usually for debugging purposes</p></li>
<li><p>debug: used to output very detailed information, usually for debugging purpose</p></li>
<li><p>info: informational logs</p></li>
<li><p>warn (warning): a non-critical error or event that we should fix. The application can still operate.</p></li>
<li><p>error: an error that we should fix. The application can still operate.</p></li>
<li><p>fatal: a critical error, the application will be stopped</p></li>
<li><p>panic: the highest level of criticality, the application will be stopped</p></li>
</ul>
<p>You can then set at which level your application will produce logs.</p>
<p>By default, trace and debug levels are not printed.</p>
<p>For example, if you want to output <strong>all levels</strong> use the following line :</p>
<pre v-highlightjs><code class="go" v-pre >log.SetLevel(log.TraceLevel)</code></pre>
<p>If you want to output only errors (and also fatal / panic) :</p>
<pre v-highlightjs><code class="go" v-pre >log.SetLevel(log.ErrorLevel)</code></pre>
<div id="fields" class="anchor"></div>
<h2 data-number="7.3"><span class="header-section-number">7.3</span> Fields <a href="#fields"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>You can add fields to your log messages. A field consists of a key and a value. A monitoring solution can easily parse fields</p>
<pre v-highlightjs><code class="go" v-pre >// logging/main.go 

workerLogger := log.WithFields(log.Fields{
    &quot;source&quot;: &quot;worker&quot;,
})
workerLogger.Info(&quot;worker has finished processed task&quot;)

mysqlLogger := log.WithFields(log.Fields{
    &quot;source&quot;: &quot;db&quot;,
    &quot;dbType&quot;: &quot;mysql&quot;,
})
mysqlLogger.Error(&quot;impossible to establish connexion&quot;)</code></pre>
<p>Will generate the following logs :</p>
<pre v-highlightjs><code class="go" v-pre >time=&quot;2021-02-19T12:49:47+01:00&quot; level=info msg=&quot;worker has finished processed task&quot; source=worker
time=&quot;2021-02-19T12:49:47+01:00&quot; level=error msg=&quot;impossible to establish connexion&quot; dbType=mysql source=db</code></pre>
<div id="logging-format-the-syslog-approach" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> Logging Format: the Syslog approach <a href="#logging-format-the-syslog-approach"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Syslog is an open standard for message logging. This section will detail some best practices extracted from RFC 5424, which specifies the format of log messages. Here is a list of fields that you can add to your logger to increase its efficiency :</p>
<div class="list">
<p>it describes which part of the system has sent the log message</p>
</div>
<p>The RFC gives the set of all possible facilities:</p>
<table>
<caption>Syslog facilities <b-link class="citation" data-cites="gerhards2009syslog" href="#gerhards2009syslog" >[@gerhards2009syslog]</b-link></caption>
<thead>
<tr class="header">
<th style="text-align: center;">Code</th>
<th style="text-align: center;">Facility</th>
</tr>
</thead>
<tbody>
<tr class="odd">
<td style="text-align: center;">0</td>
<td style="text-align: center;">kernel messages</td>
</tr>
<tr class="even">
<td style="text-align: center;">1</td>
<td style="text-align: center;">user-level messages</td>
</tr>
<tr class="odd">
<td style="text-align: center;">2</td>
<td style="text-align: center;">mail system</td>
</tr>
<tr class="even">
<td style="text-align: center;">3</td>
<td style="text-align: center;">system daemons</td>
</tr>
<tr class="odd">
<td style="text-align: center;">4</td>
<td style="text-align: center;">security/authorization messages</td>
</tr>
<tr class="even">
<td style="text-align: center;">5</td>
<td style="text-align: center;">messages generated internally by syslogd</td>
</tr>
<tr class="odd">
<td style="text-align: center;">6</td>
<td style="text-align: center;">line printer subsystem</td>
</tr>
<tr class="even">
<td style="text-align: center;">7</td>
<td style="text-align: center;">network news subsystem</td>
</tr>
<tr class="odd">
<td style="text-align: center;">8</td>
<td style="text-align: center;">UUCP subsystem</td>
</tr>
<tr class="even">
<td style="text-align: center;">9</td>
<td style="text-align: center;">clock daemon</td>
</tr>
<tr class="odd">
<td style="text-align: center;">10</td>
<td style="text-align: center;">security/authorization messages</td>
</tr>
<tr class="even">
<td style="text-align: center;">11</td>
<td style="text-align: center;">FTP daemon</td>
</tr>
<tr class="odd">
<td style="text-align: center;">12</td>
<td style="text-align: center;">NTP subsystem</td>
</tr>
<tr class="even">
<td style="text-align: center;">13</td>
<td style="text-align: center;">log audit</td>
</tr>
<tr class="odd">
<td style="text-align: center;">14</td>
<td style="text-align: center;">log alert</td>
</tr>
<tr class="even">
<td style="text-align: center;">15</td>
<td style="text-align: center;">clock daemon (note 2)</td>
</tr>
<tr class="odd">
<td style="text-align: center;">16</td>
<td style="text-align: center;">local use 0</td>
</tr>
<tr class="even">
<td style="text-align: center;">17</td>
<td style="text-align: center;">local use 1</td>
</tr>
<tr class="odd">
<td style="text-align: center;">18</td>
<td style="text-align: center;">local use 2</td>
</tr>
<tr class="even">
<td style="text-align: center;">19</td>
<td style="text-align: center;">local use 3</td>
</tr>
<tr class="odd">
<td style="text-align: center;">20</td>
<td style="text-align: center;">local use 4</td>
</tr>
<tr class="even">
<td style="text-align: center;">21</td>
<td style="text-align: center;">local use 5</td>
</tr>
<tr class="odd">
<td style="text-align: center;">22</td>
<td style="text-align: center;">local use 6</td>
</tr>
<tr class="even">
<td style="text-align: center;">23</td>
<td style="text-align: center;">local use 7</td>
</tr>
</tbody>
</table>
<p>The Go Syslog package has already an enum of the facilities, so you do not have to redevelop it :</p>
<pre v-highlightjs><code class="go" v-pre >//src/log/syslog/syslog.go
//...
const (
    // Facility.

    // From /usr/include/sys/syslog.h.
    // These are the same up to LOG_FTP on Linux, BSD, and OS X.
    LOG_KERN Priority = iota &lt;&lt; 3
    LOG_USER
    LOG_MAIL
    LOG_DAEMON
    LOG_AUTH
    LOG_SYSLOG
    LOG_LPR
    LOG_NEWS
    LOG_UUCP
    LOG_CRON
    LOG_AUTHPRIV
    LOG_FTP
    _ // unused
    _ // unused
    _ // unused
    _ // unused
    LOG_LOCAL0
    LOG_LOCAL1
    LOG_LOCAL2
    LOG_LOCAL3
    LOG_LOCAL4
    LOG_LOCAL5
    LOG_LOCAL6
    LOG_LOCAL7
)
//...</code></pre>
<div class="list">
<p>each log message do not have the same seriousness; some messages are pure information, but others require you to take immediate action to maintain the service up. For instance, if you are running out of disk space, the log should be at a high level of severity because if you do not take action immediately, it will make your service crash.</p>
</div>
<p>The specification gives us also a list of severity level that is interesting :</p>
<table>
<caption>Severity Level Syslog <b-link class="citation" data-cites="gerhards2009syslog" href="#gerhards2009syslog" >[@gerhards2009syslog]</b-link></caption>
<thead>
<tr class="header">
<th style="text-align: center;">Code</th>
<th style="text-align: center;">Level</th>
</tr>
</thead>
<tbody>
<tr class="odd">
<td style="text-align: center;">0</td>
<td style="text-align: center;">Emergency: the system is unusable</td>
</tr>
<tr class="even">
<td style="text-align: center;">1</td>
<td style="text-align: center;">Alert: we must take action immediately</td>
</tr>
<tr class="odd">
<td style="text-align: center;">2</td>
<td style="text-align: center;">Critical: critical conditions</td>
</tr>
<tr class="even">
<td style="text-align: center;">3</td>
<td style="text-align: center;">Error: error conditions</td>
</tr>
<tr class="odd">
<td style="text-align: center;">4</td>
<td style="text-align: center;">Warning: warning conditions</td>
</tr>
<tr class="even">
<td style="text-align: center;">5</td>
<td style="text-align: center;">Notice: normal but significant condition</td>
</tr>
<tr class="odd">
<td style="text-align: center;">6</td>
<td style="text-align: center;">Informational: informational messages</td>
</tr>
<tr class="even">
<td style="text-align: center;">7</td>
<td style="text-align: center;">Debug: debug-level messages</td>
</tr>
</tbody>
</table>
<p>The go Syslog package also has an enum for the severity :</p>
<pre v-highlightjs><code class="go" v-pre >//src/log/syslog/syslog.go
//...
const (
    // Severity.

    // From /usr/include/sys/syslog.h.
    // These are the same on Linux, BSD, and OS X.
    LOG_EMERG Priority = iota
    LOG_ALERT
    LOG_CRIT
    LOG_ERR
    LOG_WARNING
    LOG_NOTICE
    LOG_INFO
    LOG_DEBUG
)
// ...</code></pre>
<div class="list">
<p>this is the date, time, and timezone at which the log has been written (and in extension when the event has happened). The RFC 5424 recommends using a specific format (specified in the RFC 3339). To get the timestamp in the requested format, you can use the following code :</p>
</div>
<pre v-highlightjs><code class="go" v-pre >timestamp := time.Now().Format(time.RFC3339)</code></pre>
<div class="list">
<p>when you aggregate logs, they can come from different machines; you have to be able to know which machine has encountered a problem an easy way to get it is to make a call to <span v-highlightjs><code class="go" v-pre style="display: inline">os.Hostname()</code></span>. This last instruction will ask the kernel to answer the question :</p>
</div>
<pre v-highlightjs><code class="go" v-pre >hostname := os.Hostname()</code></pre>
<div class="list">
<p>populate this property with the name of your program. This will allow you to differentiate in the logs which application has produced the logs. It can be a time-saving habit if you have several applications that run on the same machine. Example: <strong>router</strong></p>
<p>if you want to make your logs usable, you have to make them parsable. To do so, you can add structured data to your log. By structures data, we mean a set of key-value pairs that brings context and additional information to your log.</p>
</div>
<p>The priority value (named PRIVAL) is equal to <span v-highlightjs><code class="go" v-pre style="display: inline">facility * 8 + severity</code></span></p>
<div id="example-log-formatted-following-rfc-5424" class="anchor"></div>
<h4 data-number="8.0.0.1"><span class="header-section-number">8.0.0.1</span> Example log formatted following RFC 5424 <a href="#example-log-formatted-following-rfc-5424"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<pre v-highlightjs><code class="go" v-pre >&lt;9&gt;1 2018-10-17T20:56:58+02:00 Mx.local router help me please</code></pre>
<div id="send-logs-to-a-deamon" class="anchor"></div>
<h4 data-number="8.0.0.2"><span class="header-section-number">8.0.0.2</span> Send logs to a deamon <a href="#send-logs-to-a-deamon"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>We can send logs to a log deamon. A <strong>deamon</strong> is a program that runs continuously in the background. A deamon log is a program that is responsible for collecting logs from applications :</p>
<pre v-highlightjs><code class="go" v-pre >logwriter, err := syslog.New(syslog.LOG_WARNING|syslog.LOG_DAEMON, &quot;loggingTestProgram&quot;)
if err != nil {
    log.Fatal(err)
}
logwriter.Emerg(&quot;emergency sent to syslog. TEST2&quot;)</code></pre>
<p>We will add the log to the system logs of the machine. Because it’s an emergency, it will be broadcasted to open terminals (see figure).</p>
<figure>
<b-img :src="require('@/assets/images/emergency_bradcast.png')" alt="An emergency Syslog message broadcasted to a terminal"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">An emergency Syslog message broadcasted to a terminal</figcaption>
</figure>
<div id="test-yourself" class="anchor"></div>
<h1 data-number="9"><span class="header-section-number">9</span> Test yourself <a href="#test-yourself"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="questions" class="anchor"></div>
<h2 data-number="9.1"><span class="header-section-number">9.1</span> Questions <a href="#questions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>A call to <span v-highlightjs><code class="go" v-pre style="display: inline">log.Fatal</code></span> will execute deferred functions. True or False?</p></li>
<li><p>A call to <span v-highlightjs><code class="go" v-pre style="display: inline">log.Panic</code></span> will execute deferred functions. True or False?</p></li>
<li><p>Order the following log levels in terms of criticality (from low to high): error, panic, info, trace.</p></li>
<li><p>Why adding structured data to your logs is considered a good practice?</p></li>
</ol>
<div id="answers" class="anchor"></div>
<h2 data-number="9.2"><span class="header-section-number">9.2</span> Answers <a href="#answers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>A call to <span v-highlightjs><code class="go" v-pre style="display: inline">log.Fatal</code></span> will execute deferred functions. True or False?</p>
<ol type="1">
<li><p>False</p></li>
<li><p>Deferred functions will not be run</p></li>
<li><p>The program will exit with code 1</p></li>
</ol></li>
<li><p>A call to <span v-highlightjs><code class="go" v-pre style="display: inline">log.Panic</code></span> will execute deferred functions. True or False?</p>
<ol type="1">
<li>True</li>
</ol></li>
<li><p>Order the following log levels in term of criticality (from low to high): fatal, error, panic, info, trace</p>
<ol type="1">
<li>trace, info, error, panic</li>
</ol></li>
<li><p>Why adding structured data to your logs is considered a good practice?</p>
<ol type="1">
<li><p>Structured data are easy to parse by monitoring systems</p></li>
<li><p>Consequently, logs with structured data can be exploited more efficiently by monitoring tools and teams.</p></li>
</ol></li>
</ol>
<div id="key-takeaways" class="anchor"></div>
<h1 data-number="10"><span class="header-section-number">10</span> Key Takeaways <a href="#key-takeaways"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>An application should produce informative logs</p></li>
<li><p>Logs can help developers to debug their applications when a failure occurs.</p></li>
<li><p>We can write logs on a file or on any type that implements the interface<span v-highlightjs><code class="go" v-pre style="display: inline">io.Writer</code></span></p></li>
<li><p>The <span v-highlightjs><code class="go" v-pre style="display: inline">log</code></span> package from the standard library is a good starting point when you need to add logs to your application.</p></li>
<li><p>When <span v-highlightjs><code class="go" v-pre style="display: inline">log.Fatal</code></span> is called deferred functions are not run</p></li>
<li><p>When <span v-highlightjs><code class="go" v-pre style="display: inline">log.Panic</code></span> is called deferred functions are run</p></li>
<li><p>The <span v-highlightjs><code class="go" v-pre style="display: inline">logrus</code></span> module offers interesting features such as log levels, formatting options, and fields</p></li>
<li><p>Adding parsable fields to your logs is a good practice; it makes them easier to analyze.</p></li>
</ul>
<section class="footnotes" role="doc-endnotes">
<hr />
<ol>
<li id="fn1" role="doc-endnote"><p>https://github.com/sirupsen/logrus<a href="#fnref1" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
</ol>
</section>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap31Logging"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap30Concurrency'}">
									<p><u><small>Previous</small></u></p>
									<p><small>Concurrency</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap32Templates'}">
									<p><u><small>Next</small></u></p>
									<p><small>Templates</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "Logging - Practical Go Lessons"
const description = "A log is a structured and parsable message produced by an application installed on a system. It’s the developer’s task to add logs to its application. How to use Logrus and the standard log package ?"

export default {
  name: "Chap31Logging",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
